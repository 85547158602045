const initScrollUpButton = () => {
    const scrollUpButton = document.querySelector<HTMLDivElement>('.to-the-top');

    if (!scrollUpButton) {
        return;
    }

    const toggleVisibility = () => {
        const shouldShow = window.scrollY > window.innerHeight / 2;

        scrollUpButton.classList.toggle('top-the-top_active', shouldShow);
    };

    scrollUpButton.addEventListener('click', () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    });

    window.addEventListener('scroll', toggleVisibility);

    toggleVisibility();
};

initScrollUpButton();

export default {};
