export const bodyScrollLock = {
    enable: () => {
        document.body.style.overflowY = 'hidden';
    },
    disable: () => {
        document.body.style.overflowY = '';
    },
} as const;

enum ScrollDirectionE {
    noScroll,
    up,
    down,
}

export const scrollDirection = {
    current: ScrollDirectionE.noScroll,
    directions: ScrollDirectionE,
};

let oldScroll = 0;

window.addEventListener('scroll', () => {
    scrollDirection.current = ScrollDirectionE.down;

    if (oldScroll > window.scrollY) {
        scrollDirection.current = ScrollDirectionE.up;
    }

    oldScroll = window.scrollY;
}, { passive: true });

window.dispatchEvent(new Event('scroll'));

export default {
    bodyScrollLock,
    scrollDirection,
};
