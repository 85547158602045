// @ts-ignore
// eslint-disable-next-line import/extensions
import Routing from './routing';

// @ts-ignore
const HCAPTCHA_SITE_KEY = ENV_HCAPTCHA_SITE_KEY as string;
const COMMENTS_TERMS_LINK = Routing.generate('terms-conditions');
const COMMENTS_PRIVACY_LINK = Routing.generate('privacy-policy');
const COOKIE_PREFIX = 'leakd_t';
const BI_LINK_REGEX_FOR_TRACKING = /go\.leakd\.com/i;
const COMMENTS_API_LINK = '/api/add-comment/';

export {
    HCAPTCHA_SITE_KEY,
    COOKIE_PREFIX,
    BI_LINK_REGEX_FOR_TRACKING,
    COMMENTS_PRIVACY_LINK,
    COMMENTS_TERMS_LINK,
    COMMENTS_API_LINK,
};
