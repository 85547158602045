import { on } from '../utils/dom';
import { T_REFERRER_COOKIE, T_CLICKOUT_PLACE_COOKIE, setCookieValue } from '../utils/cookie';
import { getLastArrayItem } from '../utils/array';
import { BI_LINK_REGEX_FOR_TRACKING } from '../constants';

export const clickOutElementSelector = '[data-js-clickout]';
const eventsSeparator = '|';

export type GTMDataLayerItem = {
    event: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string,
    eventValue?: string
};

export function pushToDl(GTMDataLayerItem: GTMDataLayerItem): void {
    window['dataLayer'] = window['dataLayer'] || [];

    if (typeof GTMDataLayerItem === 'object' && GTMDataLayerItem !== null) {
        window['dataLayer'].push(GTMDataLayerItem);
    }
}

export function getEventFromDatasetAtIndex(dataSet: DOMStringMap, name: string, index: number = 0) {
    return dataSet?.[name]?.split(eventsSeparator)?.[index]?.trim() ?? '';
}

export function processDomElementDataLayerEvents(domElement: HTMLElement): GTMDataLayerItem[] {
    const dataSet = domElement.dataset;
    const eventsCount = dataSet?.e?.split(eventsSeparator).length || 1;
    const result: GTMDataLayerItem[] = [];

    for (let index = 0; index < eventsCount; index += 1) {
        const getDataSetItem = (name: string) => getEventFromDatasetAtIndex(dataSet, name, index);
        const event = getDataSetItem('clickoutEvent ') || 'providerClickout';
        const eventCategory = getDataSetItem('clickoutCategory') || 'providerClickout';
        const eventLabel = getDataSetItem('clickoutLabel') || 'clicked';
        const eventAction = getDataSetItem('clickoutAction');

        const item: GTMDataLayerItem = {
            event,
            eventCategory,
            eventAction,
            eventLabel,
        };

        result.push(item);
        pushToDl(item);
    }

    return result;
}

export function handleClickoutEvent(eventTarget: HTMLElement) {
    const domLink = eventTarget.closest(clickOutElementSelector) as HTMLLinkElement;

    if (domLink) {
        if ((BI_LINK_REGEX_FOR_TRACKING).test(domLink.href)) {
            setCookieValue(T_REFERRER_COOKIE, document.location.href, true);
        }

        const gtmDataLayerItems = processDomElementDataLayerEvents(domLink);

        if (gtmDataLayerItems) {
            // eslint-disable-next-line max-len
            const affItemsFiltered = gtmDataLayerItems.filter((item) => item.eventCategory === 'providerClickout');

            const mainAffLink = getLastArrayItem(affItemsFiltered);

            if (mainAffLink) {
                setCookieValue(T_CLICKOUT_PLACE_COOKIE, `${mainAffLink.eventAction}`, true);
            }
        }
    }
}

export function onAllClickEvents(selector: string, handler: (eventTarget: HTMLElement) => void) {
    on('click', selector, (e, eventTarget) => {
        handler(eventTarget);
    });

    on('auxclick', selector, (e: MouseEvent, eventTarget) => {
        if (e.button === 1) {
            handler(eventTarget);
        }
    });

    on('contextmenu', selector, (e: MouseEvent, eventTarget) => {
        handler(eventTarget);
    });
}

export function init() {
    const dlElementSelector = '[data-js-dl]';

    onAllClickEvents(clickOutElementSelector, handleClickoutEvent);

    onAllClickEvents(dlElementSelector, (eventTarget) => {
        const domLink = eventTarget.closest(dlElementSelector) as HTMLLinkElement;

        if (domLink) {
            processDomElementDataLayerEvents(domLink);
        }
    });

    window.appWindowObject.events.on('tracking:push', pushToDl);
}

export default {
    init,
};
