import { onDomReady, querySelectorAll } from './dom';

const lazyJsAttribute = 'data-js-lazy';

// eslint-disable-next-line import/prefer-default-export
export function loadScriptFromElement(element: HTMLElement, srcAttribute = lazyJsAttribute): void {
    const src = element.getAttribute(srcAttribute);

    if (!src) {
        return;
    }

    const script = document.createElement('script');

    script.src = src;
    script.type = 'text/javascript';
    script.async = true;

    document.querySelector('body')?.append(script);

    element.removeAttribute(srcAttribute);
}

function createObserver(): IntersectionObserver {
    const lazyJsObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const element = entry.target as HTMLElement;

                loadScriptFromElement(element);
                lazyJsObserver.unobserve(element);
            }
        });
    });

    return lazyJsObserver;
}

function searchForLazyScriptsElements(initHandler: (el: HTMLElement) => void): void {
    const lazyJs = querySelectorAll(`[${lazyJsAttribute}]`);

    lazyJs.forEach(initHandler);
}

onDomReady(() => {
    if ('IntersectionObserver' in window) {
        const lazyJsObserver = createObserver();

        searchForLazyScriptsElements((el) => lazyJsObserver.observe(el));
    } else {
        searchForLazyScriptsElements(loadScriptFromElement);
    }
});
