import { createNanoEvents, Emitter } from 'nanoevents';
import { GTMDataLayerItem } from './tracking';

interface EventsI {
    ['ui:cookies']: (action: 'open' | 'close') => void,
    ['ui:modal-visible']: (id: string, isVisible: boolean) => void,
    ['ui:scroll-visible']: (id: string, isVisible: boolean) => void,
    ['tracking:push']: (GTMDataLayerItem: GTMDataLayerItem) => void,
}

type WindowT = {
    ui: {
        currentOpenedPopupId?: string | null;
        cookieBarVisible?: boolean;
        cookieBarRejectMarketing?: boolean;
    },
    events: Emitter<EventsI>,
    checker?: any,
};

const appWindowObject:WindowT = {
    ui: {
        currentOpenedPopupId: null,
        cookieBarVisible: false,
        cookieBarRejectMarketing: false,
    },
    events: createNanoEvents<EventsI>(),
    checker: null,
};

declare global {
    interface Window {
        appWindowObject: WindowT
    }
}

window.appWindowObject = appWindowObject;

export default appWindowObject;
