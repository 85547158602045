const openButton = document.querySelector('[data-navigation-open]') as HTMLElement;
const closeButton = document.querySelector('[data-navigation-close]') as HTMLElement;
const menu = document.querySelector('[data-navigation-menu]') as HTMLElement;
const main = document.querySelector('[data-navigation-main]') as HTMLElement;
const body = document.querySelector('[data-navigation-body]') as HTMLElement;
const bodyActiveClass = 'main_active';
const menuActiveClass = 'menu_active';
const navigationItemActive = 'header__navigation-item_active';
const bodyOverlay = 'body_overlay';

function closeMenu() {
    closeButton.classList.remove(navigationItemActive);
    menu.classList.remove(menuActiveClass);
    main.classList.remove(bodyActiveClass);
    openButton.classList.add(navigationItemActive);
    body.classList.remove(bodyOverlay);
}

function initNavigation() {
    if (openButton && closeButton && menu && main && body) {
        openButton.addEventListener('click', (event) => {
            if (event instanceof MouseEvent) {
                event.stopPropagation();
            }

            openButton.classList.remove(navigationItemActive);
            menu.classList.add(menuActiveClass);
            main.classList.add(bodyActiveClass);
            closeButton.classList.add(navigationItemActive);
            body.classList.add(bodyOverlay);
        });

        closeButton.addEventListener('click', (event) => {
            event.stopPropagation();
            closeMenu();
        });

        main.addEventListener('click', () => {
            if (menu.classList.contains(menuActiveClass)) {
                closeMenu();
            }
        });
    }
}

if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initNavigation);
} else {
    initNavigation();
}

export default {};
